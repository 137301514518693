import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DropDownBtn from "./DropDownBtn";
import { Button } from "react-bootstrap";
import { UserContext } from "../../context/UserContext";
import { Auth } from "aws-amplify";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  navbar: {
    marginBottom: "40px",
    position: "relative",
    zIndex: "10",
  },
}));

export default function MenuAppBar({ setPortfolio, optionData }) {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  return (
    <AppBar position="static" className={classes.navbar}>
      <Toolbar>
        <Typography variant="h3" className={classes.title}>
          Option Strategy Builder
        </Typography>
        <Button
          onClick={() =>
            window.location.replace(process.env.REACT_APP_STRIPE_REDIRECT_URL)
          }
        >
          Donate
        </Button>

        <DropDownBtn setPortfolio={setPortfolio} optionData={optionData} />
        {!user ? (
          <Button onClick={() => Auth.federatedSignIn()}>Login</Button>
        ) : (
          <Button onClick={() => Auth.signOut()}>Sign Out</Button>
        )}
      </Toolbar>
    </AppBar>
  );
}
