import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import portfolioReducer from "./store/reducers/portfolio";
import strategyReducer from "./store/reducers/strategy-info";
import { createStore, combineReducers } from "redux";
import { Paper } from "@material-ui/core";
import { HashRouter } from "react-router-dom";
import { UserContextProvider } from "./context/UserContext";

const rootReducer = combineReducers({
  portfolio: portfolioReducer,
  strategyInfo: strategyReducer,
});

const store = createStore(rootReducer);

const Index = () => {
  return (
    <React.StrictMode>
      <HashRouter>
        <Provider store={store}>
          <UserContextProvider>
            <Paper>
              <App />
            </Paper>
          </UserContextProvider>
        </Provider>
      </HashRouter>
    </React.StrictMode>
  );
};

ReactDOM.render(<Index />, document.getElementById("root"));
