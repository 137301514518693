/**
 * Returns a num rounded to a precision.
 * Used whenever a number is rendered to the screen
 * @param {*} num
 * @param {*} precision
 * @returns string
 */
export const renderNumberToPrecision = (num, precision = 2) => {
  if (typeof num != "number") return "";
  return num.toFixed(precision);
};
