import React, { useState, useCallback, useEffect, useContext } from "react";
import Payoff from "./components/Payoff/Payoff";
import StockData from "./components/StockData/StockData";
import Panel from "./components/Panel/Panel";
import * as util from "./utility";
import { useSelector, useDispatch } from "react-redux";
import Navigation from "./components/Navigation/Navigation";
import moment from "moment";
import axios from "axios";
import ColorPicker from "./utility/DS/ColorPicker";
import * as actions from "./store/actions/portfolio";
import useUpdateEffect from "./hooks/useUpdateEffect";
import Error from "./components/Error/Error";
import SecurityInfo from "./components/SecurityInfo/SecurityInfo";
import { Switch, makeStyles } from "@material-ui/core";
import { FormGroup, FormControlLabel, Container } from "@material-ui/core";
import { Card, CardContent, TextField, CardHeader } from "@material-ui/core";
import { Grid, Button } from "@material-ui/core";
import HighChart from "./components/PayoffHighChart/PayoffHighChart";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import TocIcon from "@material-ui/icons/Toc";
import GreekTable from "./components/GreekTable/GreekTable";
import { useLocation } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import InfoMessage from "./components/InfoMessage/InfoMessage";
import { Amplify, Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import { UserContext } from "./context/UserContext";

// Override some fields for env
awsExports.oauth.redirectSignIn = process.env.REACT_APP_REDIRECT_SIGN_IN;
awsExports.oauth.redirectSignOut = process.env.REACT_APP_REDIRECT_SIGN_OUT;
awsExports.oauth.domain = process.env.REACT_APP_AUTH_DOMAIN;

Amplify.configure(awsExports);

const useStyles = makeStyles({
  payoff: {
    height: "100%",
  },
  btn: {
    margin: "2px",
  },
});

const App = () => {
  const { user } = useContext(UserContext);
  const reduxState = useSelector((state) => state.portfolio);
  const { portfolio, stockData } = reduxState;
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);
  const [optionData] = useState();
  const [minX, setMinX] = useState();
  const [maxX, setMaxX] = useState();
  const [viewHighChart, setViewHighChart] = useState(true);
  const [view, setView] = useState(util.ViewType.PayoffChart);
  const [hcData, setHcData] = useState(null);
  const classes = useStyles();
  const location = useLocation();

  // Set Error Message as JSX
  const setErrs = useCallback((message) => {
    setErrors(<Error removeFunc={() => setErrors(null)}>{message}</Error>);
  }, []);

  const saveStrategy = async () => {
    if (!user) {
      Auth.federatedSignIn();
      return;
    }
    const res = await axios.post(process.env.REACT_APP_API_URL + "/items", {
      portfolio,
      stockData,
    });
    const msg = "Strategy saved at url: " + process.env.REACT_APP_HOST + "/#/";
    setErrs(msg + res.data);
  };

  // Update and Validate User Input Data
  const updateData = () => {
    // Validate Empty Portfolio
    if (Object.keys(portfolio).length === 0)
      return setErrs("Add contracts to Visualize");

    // Validate Stock Price
    if (+stockData.currentPrice <= 0)
      return setErrs("Please Enter a Valid Stock Price");

    // Validate Interest
    if (isNaN(+stockData.interest))
      return setErrs("Please Enter a Valid Interest Rate");

    const strikes = [];
    let maxStrike = 0;
    let minStrike = Infinity;

    const values = [];
    const theoretical = [];

    // Add the min/max x values input by the user and update the max/minStrikes
    if (maxX) {
      if (+maxX > maxStrike) maxStrike = +maxX;
      if (+maxX < minStrike) minStrike = +maxX;
      strikes.push(+maxX);
    }

    if (minX) {
      if (+minX > maxStrike) maxStrike = +minX;
      if (+minX < minStrike) minStrike = +minX;
      strikes.push(+minX);
    }

    // Get the Critical strikes to plot
    for (let id in portfolio) {
      const contract = portfolio[id];
      const cashContract = contract.type === util.CASH;
      const strike = cashContract ? +stockData.currentPrice : +contract.strike;
      const date = contract.date;
      const amount = contract.amount;

      // Always Validate Amount
      if (amount <= 0) return setErrs("Please Enter a Valid Amount");

      // If not a Cash Contract then validate the following fields
      if (!cashContract) {
        // Validate Strike prices
        if (strike <= 0) return setErrs("Please Enter A Valid Strike Price");

        // Validate the Date (Check if it is defined and in the future)
        if (!date || moment().diff(date) > 0)
          return setErrs("Please Enter a Valid Date");
      }

      strikes.push(strike);

      // Update the maxStrike
      if (strike > maxStrike) maxStrike = strike;
      if (strike < minStrike) minStrike = strike;
    }

    const average = (maxStrike + minStrike) / 2;
    let max = 0;

    // If maxX is set
    if (!maxX) {
      // Auto xMax margin
      max = Math.floor(maxStrike + average * 0.2);
    } else {
      max = Math.max(+maxX, maxStrike);
    }

    let min = 0;

    // If minX is set
    if (!minX) {
      // Auto xMin Margin
      min = Math.floor(minStrike - average * 0.2);
    } else {
      min = Math.min(+minX, minStrike);
    }

    const change = (max - min) / 35;

    // Add domain limits
    strikes.push(min);
    strikes.push(max);

    setMaxX(max);
    setMinX(min);

    let i = min;
    // Add The rest of the strikes for continuous feel
    while (i < max) {
      // Round to 2 decimals and convert back to number
      strikes.push(i);
      i += change;
    }

    // Sort the strikes so the graph can be displayed properly
    strikes.sort((a, b) => a - b);

    const result = [];

    // Helper DS to fetch colors
    const colors = new ColorPicker();

    // We want our plot to have N lines (N is the number of contracts in portfolio)
    for (let id in portfolio) {
      const contract = portfolio[id];
      let key =
        contract.direction + " " + contract.type + " " + contract.strike;
      // Adjust title if cash contract
      if (contract.type === util.CASH) {
        key =
          contract.direction +
          " " +
          contract.type +
          "  " +
          stockData.currentPrice;
      }

      result.push({
        values: [],
        key,
        color: colors.getColor(),
        disabled: true,
      });
    }

    // Keey track of min/max for Ydomain
    let minProfit = Infinity;
    let maxProfit = -Infinity;

    // For each strike, calculate the payoff and add it to values
    for (let strike of strikes) {
      let profitSum = 0;
      let theoreticalPL = 0;
      // Keep track of the index we are at
      let i = 0;
      for (let id in portfolio) {
        const contract = portfolio[id];

        // Calculate profit at given Strike (at Expiration)
        const profitAtStrike = util.evaluatePayoffFunc(
          contract,
          strike,
          stockData
        );

        // Calculate dateDifference in years, used in theoretical black scholes
        const dateDiff = -moment().diff(contract.date, "years", true);

        const blackScholesValue = util.BlackScholes(
          contract.type,
          +strike,
          +contract.strike,
          +dateDiff,
          +stockData.interest,
          +stockData.volatility
        );

        // If the contract is Cash
        if (contract.type === util.CASH) {
          // Just add the profit at Strike
          theoreticalPL += +profitAtStrike;
        } else {
          // Calculate depending on Buy/Sell
          if (contract.direction === util.BUY) {
            // Calculate Theoretical P/L
            theoreticalPL +=
              (blackScholesValue - contract.price) * contract.amount;
          } else {
            theoreticalPL +=
              (contract.price - blackScholesValue) * contract.amount;
          }
        }

        // Update min and max Profits
        if (profitAtStrike > maxProfit) maxProfit = profitAtStrike;

        if (profitAtStrike < minProfit) minProfit = profitAtStrike;

        // Push the point at the specified strategy
        result[i].values.push({
          x: strike,
          y: profitAtStrike,
        });

        // Evaluate each contract in portfolio and add it to the y value
        profitSum += profitAtStrike;
        i++;
      }

      // Add the point to the data
      values.push({ x: strike, y: profitSum });
      theoretical.push({ x: strike, y: theoreticalPL });
    }

    const Ydomain = [Math.floor(minProfit * 1.2), Math.floor(maxProfit * 1.2)];

    // The overall strategy plot data
    const strategyData = {
      values,
      key: "At Expiration",
      color: "green",
      disabled: false,
    };

    // The Theoretical strategy plot data
    const strategyTheoretical = {
      values: theoretical,
      key: "Today",
      color: "pink",
      disabled: false,
    };

    result.push(strategyTheoretical);

    result.push(strategyData);

    // Clear the Errors
    setErrors(null);

    if (viewHighChart) {
      const res = [];
      // Parse data into HighChart Format
      for (let series of result) {
        const seriesInfo = {
          data: [],
          visible: !series.disabled,
          color: series.color,
          name: series.key,
        };
        res.push(seriesInfo);
        for (let point of series.values) {
          seriesInfo.data.push([util.round(point.x), util.round(point.y)]);
        }
      }

      return setHcData({
        series: res,
        xAxis: {
          title: {
            text: "Stock Price ($)",
          },
        },
        yAxis: {
          title: {
            text: "Profit ($)",
          },
        },
        chart: {
          type: "spline",
        },
        title: {
          text: "",
        },
      });
    }

    setData({ data: result, Ydomain });
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/items" + location.pathname
      );
      dispatch(actions.setData(res.data));
    };

    const existingPortfolio = localStorage.getItem("portfolio");
    const existingStockData = localStorage.getItem("stockData");

    console.log(existingPortfolio);
    console.log(existingStockData);

    if (existingPortfolio || existingStockData) {

      const parsedExistingPortfolio = JSON.parse(existingPortfolio);
      const parsedExistingStockData = JSON.parse(existingStockData);
      
      console.log(parsedExistingPortfolio);
      console.log(parsedExistingStockData);

      dispatch(
        actions.setData({
          portfolio: parsedExistingPortfolio,
          stockData: parsedExistingStockData,
        })
      );
    }
    fetchData();
  }, []);

  // Custom hook used to Reset Porfolio only when optionData changes
  useUpdateEffect(() => {
    dispatch(actions.resetPortfolio());
  }, [optionData]);

  // Updating data to display
  useEffect(() => {
    updateData();
  }, [portfolio, maxX, minX, viewHighChart]);

  return (
    <>
      <Navigation optionData={optionData} />
      <Container>
        <Grid container spacing={1}>
          <Grid item md={12}>
            {stockData.ticker !== util.THEORETICAL_TICKER && <SecurityInfo />}
          </Grid>
          <Grid item md={12}>
            <Panel
              optionData={optionData}
              portfolio={portfolio}
              visualize={updateData}
            />
          </Grid>
          <Grid item md={3}>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <StockData />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={9}>
            {errors ? errors : null}
            <Card variant="outlined" className={classes.payoff}>
              <CardHeader
                avatar={
                  <>
                    {view === util.ViewType.PayoffChart ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!viewHighChart}
                              onChange={() => setViewHighChart((prev) => !prev)}
                              aria-label="graph type switch"
                            />
                          }
                          label="Switch Graph Type"
                        />
                      </FormGroup>
                    ) : null}
                  </>
                }
                action={
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<ShowChartIcon fontSize="large" />}
                      className={classes.btn}
                      onClick={() => setView(util.ViewType.PayoffChart)}
                    >
                      Payoff
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<TocIcon fontSize="large" />}
                      className={classes.btn}
                      onClick={() => setView(util.ViewType.Greeks)}
                    >
                      Greeks
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<SaveIcon fontSize="large" />}
                      className={classes.btn}
                      onClick={saveStrategy}
                    >
                      Save Strategy
                    </Button>
                  </>
                }
              />
              <CardContent>
                {view === util.ViewType.Greeks ? (
                  <>
                    <GreekTable data={reduxState} />
                  </>
                ) : (
                  <>
                    {!viewHighChart ? (
                      <Payoff data={data} />
                    ) : (
                      <HighChart data={hcData} />
                    )}
                    <Grid container>
                      <Grid item md={2}>
                        <TextField
                          id="outlined-number"
                          label="Low"
                          placeholder="Auto"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(e) => setMinX(e.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={8}></Grid>
                      <Grid item md={2}>
                        <TextField
                          id="outlined-number"
                          label="High"
                          placeholder="Auto"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(e) => setMaxX(e.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <InfoMessage />
      </Container>
    </>
  );
};

export default App;
