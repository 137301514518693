/**
 * Color Picker Structure to help select available colors on graph.
 */
export default class ColorPicker {
  static BLUE = "blue";
  static RED = "red";
  static YELLOW = "yellow";
  static PURPLE = "purple";
  static ORANGE = "orange";
  static BLACK = "black";

  /**
   * Available colors.
   */
  static colors = [
    this.BLUE,
    this.RED,
    this.YELLOW,
    this.PURPLE,
    this.ORANGE,
    this.BLACK,
  ];

  constructor() {
    this.index = 0;
  }

  /**
   * Get the next color.
   * @returns nextColor - string.
   */
  getColor() {
    const result = ColorPicker.colors[this.index];
    this.index++;
    this.index %= ColorPicker.colors.length;
    return result;
  }
}
