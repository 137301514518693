import * as cts from "./constants";

// Option definition class
export default class Option {
  constructor(contractName = Option.defaultContractName()) {
    this.contractName = contractName; // Replace because of mongoose error
    this.strike = 100;
    this.date = cts.date;
    this.direction = cts.BUY;
    this.type = cts.CALL;
    this.amount = 1;
    this.debitCredit = 0;
    this.volatility = 30;
  }
}

Option.defaultContractName = () => {
  return new Date().toISOString().replace(".", "|");
}
